<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Venue
      </h1>

      <div class="mb-4">
        <!-- component -->
        <div id="recipients" class="">
          <table id="ytd-tickets" class="border-collapse w-full text-sm">
            <thead>
              <tr>
                <th
                  data-priority="1"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  VenueId
                </th>
                <th
                  data-priority="2"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  VenueName
                </th>
                <th
                  data-priority="3"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  City
                </th>
              </tr>
            </thead>
            <tbody id="ticketsList">
              <tr
                v-if="!data.length"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                  colspan="11"
                >
                  {{ loading }}
                </td>
              </tr>
              <tr
                v-for="tr in data"
                :key="tr.id"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                id="ytd-tix-data-wait"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.venueid }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.venuename }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.city }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Venue",
  data() {
    return {
      loading: "Please wait...",
      data: []
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$axios
        .get(`${this.$api}/ticket/venue`)
        .then(res => {
          this.data = res.data.data;
          if (this.data.length === 0) {
            this.loading = "No data found";
          }
          if (!res.data.success) {
            this.loading = "No data found";
          }
          var self = this;
          var existTnTix = setInterval(function() {
            if (self.jQuery("#ytd-tix-data-wait").length) {
              clearInterval(existTnTix);
              self.jQuery("#ytd-tickets").DataTable({
                responsive: true,
                aLengthMenu: [100]
              });
            }
          }, 100);
        })
        .catch(err => {
          console.log(err);
          this.loading = "Something went wrong, please try again";
        });
    }
  }
};
</script>
